@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  	@apply m-0;
	@apply font-poppins;
  	@apply bg-white;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	  overflow-x: hidden;
	
}

.owl-carousel .owl-stage {
    display: flex !important;
}

.owl-carousel .owl-item {
	margin-bottom: 50px;
}
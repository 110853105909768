.innerBanner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}
.innerBanner2{
    
}
.banner2 {
    height: 300px;
}
.banner {
    height: 700px;
}

@media screen and (max-width: 1000px) {
    .banner {
        height: 500px;
    }
}

@media screen and (max-width: 600px) {
    .banner {
        height: 300px;
    }
}
@media screen and (max-width: 300px) {
    .banner {
        height: 300px;
    }
}

:root {
    --primaryColor: #5784dc;
    --heading: #565a71;
    --background: #f3f3f3;
    --danger-color: #e96262;
    --footer-color: #1e2334;
    --subheading: #8b8b8b;
    --card-1: #ffeea3;
    --card-2: #8b0da0;
    --card-3: #30b0f5;
    --card-4: #9adcff;
    --card-5: #fdc948;
    --card-6: #ffd365;
    --card-7: #d96098;
    --card-8: #f89090;
    --card-9: #ffa8a8;
    --card-10: #d96098;
    --card-11: #28e451;
    --card-12: #ff6565;
    --card-13: #fd4848;
    --icon-1: #3f58da;
    --icon-2: #f8e53c;
    --iconColor:#E9E9E9;

    --card-box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.25);

    --tertiary-blue: #7b8beb;
    --tertiary-blue2: #dde3ff;

    --btn-radius: 12px;

    --disabled-checkbox: #f3f3f3;

    --input-border: #f3f3f3;
}

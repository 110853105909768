.Banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

.infoBox {
    margin-top: 40px;
    border-radius: 4px;
    background: linear-gradient(
        269.46deg,
        rgba(255, 255, 255, 0.09) 0%,
        rgba(255, 255, 255, 0.09) 100%
    );
    border: 1px solid #818181;
    backdrop-filter: blur(4px);
    padding: 20px 28px;
}

.infoBox2 {
    margin-top: 5px;
    border-radius: 4px;
    background: linear-gradient(
        269.46deg,
        rgba(255, 255, 255, 0.09) 0%,
        rgba(255, 255, 255, 0.09) 100%
    );
    border: 1px solid #818181;
    backdrop-filter: blur(4px);
    padding: 20px 28px;
}

.Imgcontainer { 
    height: 200px;
    position: relative;
  }

.Imgcenter {
    margin: 0;
    position: absolute;
    top: 80%!important;
    left: 50%!important;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

 
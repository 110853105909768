.verticalLine {
    height: 25px;
    flex: 0 0 5px;
    background-color: var(--primaryColor);
    border-radius: 5px;
    margin-right: 15px;
}

@media screen and (max-width: 1000px) {
    .verticalLine {
        width: 15px;
    }
}
@media screen and (max-width: 600px) {
    .verticalLine {
        width: 18px;
    }
}

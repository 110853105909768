.owlSlider {
    height: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 8px;
    margin: 20px;
}

.tourBanner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.MySlider2 {
    .owl-dots {
        display: none;
    }
    .owl-nav button {
        background-color: #5784dc !important;
        width: 40px;
        height: 40px;
        border-radius: 500px !important;
        margin-left: 20px !important;
        position: absolute;
        top: 50%;
    }

    .owl-prev {
        left: -45px !important;
    }
    .owl-next {
        right: -30px !important;
    }

    .owl-prev span {
        display: none;
    }

    .owl-prev:after {
        content: "<";
        font-size: 25px;
        color: white;
    }
    .owl-next span {
        display: none;
    }

    .owl-next:after {
        content: ">";
        font-size: 25px;
        color: white;
    }
}

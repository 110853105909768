.Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color:transparent;
    border: none; 
    border-radius: 2px;
    box-sizing: border-box;
    color: white;
    cursor: default;
    outline: none;
    padding: 0px 0px 0px 0px;
    transition: all 200ms ease;
}
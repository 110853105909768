.innerBanner {
    background-size: cover;
    height: 100%;
    border-radius: 5px;
    width: 100%;
    transition: 0.3s ease;
    cursor: pointer;
    background-repeat: no-repeat;
    &:hover {
        transform: translateY(-5px);
    }
}
.banner {
    height: 400px;
    border-radius: 20px;
    width: 100%;
    background-repeat: no-repeat;
}

@media screen and (max-width: 600px) {
    .banner {
        height: 250px;
    }
}
@media screen and (max-width: 300px) {
    .banner {
        height: 250px;
    }
}

.blurBackground {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 20px;
    max-width: 600px;
}

.heading {
    border-left: 4px solid var(--danger-color);
    color: var(--danger-color);
    margin-top: 38px;
    font-size: 20px;
}

.heading {
    padding: 6px 12px;
    border-left: 5px solid var(--primaryColor);
    border-radius: 2px;
}

.dangerRed {
    padding: 6px 12px;
    border-left: 5px solid var(--danger-color);
    border-radius: 2px;
}

.float{
	position:fixed;
	width:auto;
	height:auto;
	bottom:50%;
	right:10px;
	background-color:#5C90F6;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	z-index: 999;
}
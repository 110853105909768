.reviewsContainer {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    min-height: 250px;
    border-radius: 8px;
    margin: 10px;
}

.header {
    font-size: 16px;
}

.quote {
    width: 50px !important;
}
.star {
    width: 20px !important;

    margin-right: 20px;
}
.avatar {
    background-color: grey;
    height: 50px;
    width: 50px;
    border-radius: 500px;
}
.details {
    margin-left: 20px;

    h5 {
    }
    p {
    }
}

.Banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}
.infoBox {
    margin-top: 70px;
    border-radius: 4px;
    background: linear-gradient(
        269.46deg,
        rgba(255, 255, 255, 0.09) 0%,
        rgba(255, 255, 255, 0.09) 100%
    );
    border: 1px solid #818181;
    backdrop-filter: blur(4px);
    padding: 20px 28px;
}
.infoBoxSearch {
    margin-top: 10px;
    color: white;
    border-radius: 4px;
    background: linear-gradient(
        269.46deg,
        rgba(255, 255, 255, 0.09) 0%,
        rgba(255, 255, 255, 0.09) 100%
    );
    border: 1px solid #818181;
    backdrop-filter: blur(4px);
    padding: 20px 28px;
}

.search {
    width: 30%;
}
.innerBanner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    border-radius: 5px;
    width: 100%;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
        transform: translateY(-5px);
    }
}

.formBanner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

.banner {
    height: 500px;
    border-radius: 20px;
    width: 100%;
}

.smallBanner {
    height: 240px;
    cursor: pointer;
}

.line {
    width: 100%;
    height: 1px;
    background-color: var(--background);
    opacity: 0.5;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
    .navbarSection {
        width: 70%;
        ul li {
            font-size: 13px;
        }
    }
}

@media screen and (max-width: 900px) {
    .banner {
        height: 300px;
    }
    .smallBanner {
        height: 300px;
    }
}


.selectField {
    width: 100%;
    padding: 8px 18px;
    border-radius: 4px;
    background: linear-gradient(
        269.58deg,
        rgba(255, 255, 255, 0.21) 0%,
        rgba(255, 255, 255, 0.18) 100%
    );
    border: 1px solid #9c9c9c;
    backdrop-filter: blur(4px);
    margin-top: 10px;
    color: var(--background);
    option {
        background-color: var(--background);
        background: linear-gradient(
            269.58deg,
            rgba(255, 255, 255, 0.21) 0%,
            rgba(255, 255, 255, 0.18) 100%
        );
        border: 1px solid #9c9c9c;
        backdrop-filter: blur(4px);
        color: black !important;
    }
}
.label {
    color: white;
}
.blackLabel {
    color: var(--heading);
    margin-top: 20px;
}

.nonBlur {
    background: var(--background);
    border: 1px solid #dadada;
    width: 100%;
    padding: 8px 18px;
    border-radius: 4px;
    margin-top: 10px;
    color: var(--subheading);
    font-size: 14px !important;
}

.owlSlide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
}

.owlSlider {
    height: 100vh;
}
.insideBanner {
    visibility: hidden;
}
@media screen and (max-width: 1000px) {
    .owlSlider {
        height: 80vh;
    }
}
@media screen and (max-width: 600px) {
    .owlSlider {
        height: 50vh;
    }
}

.mySlider {
    .owl-dots {
        z-index: 89999;
        position: absolute;
        bottom: 0px;
        padding: 10px;
        width: 100%;
    }

    .owl-dot > span {
        background-color: var(--heading) !important;
    }
    .owl-dot {
        margin-right: 20px !important;
        height: 22px;
        background-color: var(--tertiary-blue2) !important;
        border-radius: 500px;
        padding: 12px;
    }

    .owl-dots > .active > span {
        background-color: var(--primaryColor) !important;
    }
}

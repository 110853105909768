.footerLinks {
    a {
        cursor: pointer;
    }
    li {
        margin-top: 20px;
        color: var(--iconColor);
    }
    margin-top: 60px !important;
    margin-bottom: 60px;
}

.mobileFooter {
    display: none !important;
}

.line {
    background-color: var(--subheading);
    width: 100%;
    height: 1px;
}

.iconComponent {
    img {
        margin-top: 40px;
        height: auto;
        width: 40px;
        margin-left: 80px;
    }
}
.mainFooter {
    display: flex;
}

@media screen and (max-width: 1100px) {
    .desktopFooter {
        display: none;
    }
    .mobileFooter {
        display: block !important;
    }
}
.mobileBottomLinks {
    a {
        margin-right: 40px;
        margin-top: 20px;
    }
}
@media screen and (max-width: 840px) {
    .mainFooter {
        display: block;
    }
    .iconComponent {
        display: flex;
        width: 100%;
        justify-content: space-between;
        img {
            margin-left: 0;
        }
    }
}

.cards {
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(250px, 1fr)
    ); /* see notes below */
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

    grid-auto-rows: minmax(300px, auto);
    grid-gap: 2rem;
    margin-top: 50px;
}
.formBox {
    padding: 80px 300px;
}

@media screen and (max-width: 1200px) {
    .formBox {
        padding: 80px 200px;
    }
}

@media screen and (max-width: 900px) {
    .formBox {
        padding: 80px 100px;
    }
}

@media screen and (max-width: 600px) {
    .formBox {
        padding: 40px 40px;
    }
}

@media screen and (max-width: 300px) {
    .formBox {
        padding: 30px 40px;
    }
}

.navbar {
    z-index: 1000;
    background: transparent;
    transition: 0.3s ease;
}
.blurBackground {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    transition: 0.3s ease;
}

.hamburgerIcon {
    display: none;
    cursor: pointer;

    img {
        width: 40px;
    }
}
.logo {
    width: 200px;
    cursor: pointer;
}

.hoverItem {
    transition: 0.3s ease;
    padding: 11px !important;
    border-radius: 12px;
    &:hover {
        color: white;
        background-color: var(--tertiary-blue);
    }
}
.navbarSection {
    width: 70%;
}

.sideNavBar {
    height: 100vh;
    background-color: var(--background);
    width: 80%;
    position: fixed;
    top: 0;
    z-index: 1100;
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;
    background: linear-gradient(
        269.58deg,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.7) 100%
    );
    backdrop-filter: blur(20px);
}

.banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 220px;
}

.overlay {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    display: none;
    background-color: var(--heading);
    opacity: 0;
    transition: 0.3s ease-in-out;
}

.sideBarActive {
    transform: translateX(0%);
}

.overlayActive {
    opacity: 0.5;
    display: block;
    z-index: 1001;
}

.sideBarLinks {
    height: 65%;
}
.line {
    width: 100%;
    background-color: var(--subheading);
    height: 1px;
}

.flagBox {
    position: absolute;
    width: 291px;
    display: none;
    opacity: 0;
    height: 249px;
    right: 0;
    margin-top: 30px;
    color: var(--heading);
    font-weight: 600;
    background: #ffffff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    transition: 0.3s ease;

    .mainBox {
        padding: 12px 18px;
        border-bottom: 0.2px solid rgb(220, 220, 220);
        &:hover {
            background-color: var(--tertiary-blue2);
        }
    }

    .flagLinks {
        margin-left: 20px;
    }
}
.activeFlag {
    opacity: 1;
    display: block;
    
}

@media screen and (max-width: 1200px) {
    .navbarSection {
        width: 70%;
        ul li {
            font-size: 13px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .navbarSection {
        display: none;
    }
    .hamburgerIcon {
        display: block;
    }
    .logoSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .flagBox {
        width: 250px;

        height: 249px;

        .mainBox {
            font-size: 14px;
            padding: 12px 14px;
            img {
                width: 25px !important;
            }
        }
    }
    .activeFlag {
        opacity: 1;
    }
}
@media screen and (max-width: 300px) {
    .navbarSection {
        display: none;
    }
    .logo {
        width: 200px;
    }
    .hamburgerIcon {
        display: block;
    }
    .logoSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100px;
    }
}

@media screen and (max-width: 150px) {
    .navbarSection {
        display: none;
    }
    .logo {
        width: 200px;
    }
    .hamburgerIcon {
        display: block;
    }
    .logoSection {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100px;
    }
}

.Banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
}

.infoBox {
    margin-top: 0px;
    border-radius: 4px;
    background: linear-gradient(
        269.46deg,
        rgba(255, 255, 255, 0.09) 0%,
        rgba(255, 255, 255, 0.09) 100%
    );
    border: 1px solid #818181;
    backdrop-filter: blur(4px);
    padding: 12px 28px;
}

.positionBottom{
    position: absolute;
    top: 200px!important;
  left: 30px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.positionBottomforMobile{
    position: absolute;
    top: -40px!important;
  left: 0px;
}
.card {
    position: relative;
    /*height: 200px;*/
    /*background: red;*/
    background: white;
    border-radius: 10px;
    padding: 20px 40px;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border: 1px solid #c0c0c0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    display: flex;
    /* -webkit-box-orient: vertical; */
    /* -webkit-box-direction: normal; */
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    color: #5d5e5e;
} /* li item */
